import UploadsApi from '@/classes/UploadsApi'

let currentPage = 1
let currentPageParams = null
let firstEvaluatedKey = null
let lastEvaluatedKey = null
let hasMoreNext = false
let hasMorePrevious = false

export default {
  currentPage() {
    return currentPage
  },
  isPreviousDisabled() {
    return !hasMorePrevious
  },
  isNextDisabled() {
    return !hasMoreNext
  },
  async getPageResults(requestParams) {
    let params = requestParams
    let requestedPage

    if (params.first) {
      // FIRST page reset
      requestedPage = 1
      firstEvaluatedKey = null
      lastEvaluatedKey = null
    } else if (params.next) {
      // Get NEXT page
      requestedPage = currentPage + 1
      if (lastEvaluatedKey) {
        params.next = lastEvaluatedKey
      }
    } else if (params.previous) {
      // Get PREVIOUS page
      requestedPage = currentPage - 1
      if (firstEvaluatedKey) {
        params.previous = firstEvaluatedKey
      }
    } else {
      // REPEAT request for current page
      requestedPage = currentPage
      params = currentPageParams
    }

    const response = await UploadsApi.listUploads(params)

    if (response.Count > 0) {
      // Update local vars (inc first and last keys) for this page of results
      currentPage = requestedPage
      currentPageParams = params
      firstEvaluatedKey = response.FirstEvaluatedKey
      lastEvaluatedKey = response.LastEvaluatedKey
    }

    // Process hasMore response to determine if there are more records in either direction
    if (params.previous) {
      hasMorePrevious = response.hasMore
      hasMoreNext = true
    } else {
      hasMorePrevious = !params.first
      hasMoreNext = response.hasMore
    }

    return response
  },
}
