import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_vm._v("Errors for file: "+_vm._s(_vm.file.filename))]),(!_vm.file.isMetadata())?_c(VDivider):_vm._e(),_c(VSimpleTable,{attrs:{"fixed-header":"","height":"60vh"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[(_vm.file.isMetadata())?_c('tr',[(_vm.file.isMetadata())?_c('th',[_vm._v("Row")]):_vm._e(),(_vm.file.isMetadata())?_c('th',[_vm._v("Row Ref")]):_vm._e(),(_vm.file.isMetadata())?_c('th',[_vm._v("Column")]):_vm._e(),_c('th',[_vm._v("Error")])]):_vm._e()]),_c('tbody',[_vm._l((_vm.file.errors.slice(0, _vm.errorCountLimit)),function(error){return _c('tr',{key:error.getUniqueKey()},[(_vm.file.isMetadata())?_c('td',[_vm._v(_vm._s(error.rowNum))]):_vm._e(),(_vm.file.isMetadata())?_c('td',[_vm._v(_vm._s(error.rowRef))]):_vm._e(),(_vm.file.isMetadata())?_c('td',[_vm._v(_vm._s(error.field))]):_vm._e(),_c('td',[_vm._v(_vm._s(error.message))])])}),(_vm.file.errors.length > _vm.errorCountLimit)?_c('tr',{key:"show_more"},[_c('td',{attrs:{"colspan":_vm.file.isMetadata() ? 4 : 1}},[_c('center',[_c(VBtn,{staticClass:"ma-4",attrs:{"color":"primary"},on:{"click":_vm.showMoreErrors}},[_vm._v(" Show more ")])],1)],1)]):_vm._e()],2)]},proxy:true}])}),_c(VDivider),_c(VCardActions,[_c(VBtn,{attrs:{"color":"error"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Close")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }