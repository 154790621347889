<template>
  <v-alert :type="style" class="mx-5" text border="left">
    {{ summary }}
    <div v-if="error">{{ error }}</div>
  </v-alert>
</template>

<script>
import icons from '@/utils/icons'
import submitErrorMsg from '@/utils/submitErrorMsg'

const dateOptions = {
  // timeZoneName: 'short',
  weekday: 'short',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
}
const formatDate = date => date.toLocaleDateString(undefined, dateOptions)

let lastSubmitDate

export default {
  data() {
    return {
      icons,
      summary: 'Loading...',
      style: 'info',
      error: null,
    }
  },
  methods: {
    init(organisation) {
      const { organisationUuid, isSubmitting, lastSubmitTime, lastSubmitState } = organisation

      if (!lastSubmitTime) {
        // does not have new data recorded yet
        this.summary = `No previous submission data is available yet`

        return
      }
      lastSubmitDate = new Date(lastSubmitTime)

      if (isSubmitting) {
        this.submitInProgress()

        return
      }

      if (lastSubmitState.isSuccess === undefined) {
        this.submitTimedOut()

        return
      }

      if (lastSubmitState.isSuccess) {
        this.submitSuccess()

        return
      }

      const errorMsg = submitErrorMsg(lastSubmitState, organisationUuid)
      this.submitFailed(errorMsg)
    },
    submitStarted() {
      lastSubmitDate = new Date()
      this.submitInProgress()
    },
    submitInProgress() {
      this.style = 'warning'
      this.summary = `Submission in progress...`
      this.error = null
    },
    submitSuccess() {
      this.style = 'success'
      this.summary = `Last submission SUCCEEDED on ${formatDate(lastSubmitDate)}`
      this.error = null
    },
    submitFailed(errorMsg) {
      this.style = 'error'
      this.summary = `Last submission FAILED on ${formatDate(lastSubmitDate)}`
      this.error = errorMsg
    },
    submitTimedOut() {
      this.style = 'error'
      this.summary = `Last submission TIMED OUT on ${formatDate(lastSubmitDate)}`
      this.error = 'Click SUBMIT to try again'
    },
  },
}
</script>
