import DB from '@/classes/DB'

const fieldOrder = ['filename', 'rowNum', 'rowRef', 'field', 'message']

const csvCell = rawValue => {
  // Handle special cases
  if (rawValue === null) return ''
  if (typeof rawValue === 'undefined') return ''
  if (typeof rawValue === 'number') return rawValue

  // Convert to string and escape quotes
  let value = String(rawValue).replace(/"/g, '""')

  // Prefix dangerous values to prevent formula injection from user provided data
  if (rawValue.charAt(0) === '=') {
    value = `\t${value}`
  }

  // Wrap values with quotes
  return `"${value}"`
}

const csvRow = row => `${fieldOrder.map(field => csvCell(row?.[field])).join(',')}\n`

export default {
  generate() {
    // @todo check that we're not waiting for errors

    // Start CSV body with header row
    let csvBody = `${fieldOrder.join(',')}\n`

    // Get ALL uploads sorted by filename
    const uploads = DB.getAllUploadsOrdered()

    // Loop through uploads and add all errors (pre-sorted) to csv
    uploads.forEach(upload => {
      const { filename } = upload
      upload.getAllErrors().forEach(error => {
        csvBody += csvRow({ filename, ...error })
      })
    })

    return csvBody
  },
}
