const errorCodeMsgs = {
  // Portal error codes
  // ERR_CALLBACK: '',
  ERR_TRANSFER: 'The submission was partially successful but there was a problem transferring your assets',

  // Target app error codes
  AUTH_FAIL: 'The user account that submitted this request could not be found in the target application',
}

export default errorCode =>
  errorCodeMsgs[errorCode] || `An unexpected system error occurred while trying to submit your files`
