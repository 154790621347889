<template>
  <amplify-authenticator style="height: 300px">
    <div>
      <VueFullScreenFileDrop :text="DropHandler.disabled ? 'Disabled, please wait' : 'Drop here'" />
      <v-row>
        <v-col cols="12">
          <dashboard-datatable></dashboard-datatable>
        </v-col>
      </v-row>
    </div>
    <amplify-sign-in slot="sign-in" :hide-sign-up="true"></amplify-sign-in>
  </amplify-authenticator>
</template>

<script>
import { Translations, onAuthUIStateChange } from '@aws-amplify/ui-components'
import { I18n } from 'aws-amplify'
import DropHandler from '@/classes/DropHandler'
import DashboardDatatable from './DashboardDatatable.vue'

export default {
  components: {
    DashboardDatatable,
  },
  data() {
    return {
      DropHandler,
    }
  },
  created() {
    onAuthUIStateChange(authState => {
      // FC-342 show the correct label for whether a requested code is coming form MFA app or email
      I18n.putVocabulariesForLanguage('en-US', {
        [Translations.CODE_LABEL]:
          authState === 'forgotpassword' ? Translations.CODE_LABEL_EMAIL : Translations.CODE_LABEL_MFA,
      })
    })
  },
  methods: {},
}
</script>
