<template>
  <v-dialog v-model="dialog">
    <v-card>
      <v-card-title>Errors for file: {{ file.filename }}</v-card-title>
      <v-divider v-if="!file.isMetadata()"></v-divider>
      <v-simple-table fixed-header height="60vh">
        <template v-slot:default>
          <thead>
            <tr v-if="file.isMetadata()">
              <th v-if="file.isMetadata()">Row</th>
              <th v-if="file.isMetadata()">Row Ref</th>
              <th v-if="file.isMetadata()">Column</th>
              <th>Error</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="error in file.errors.slice(0, errorCountLimit)" :key="error.getUniqueKey()">
              <td v-if="file.isMetadata()">{{ error.rowNum }}</td>
              <td v-if="file.isMetadata()">{{ error.rowRef }}</td>
              <td v-if="file.isMetadata()">{{ error.field }}</td>
              <td>{{ error.message }}</td>
            </tr>
            <tr v-if="file.errors.length > errorCountLimit" key="show_more">
              <td :colspan="file.isMetadata() ? 4 : 1">
                <center>
                  <v-btn color="primary" class="ma-4" @click="showMoreErrors"> Show more </v-btn>
                </center>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="error" @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import UploadFile from '@/classes/UploadFile'

const defaultErrorCountLimit = 100

export default {
  props: {
    file: {
      type: UploadFile,
      default: () => new UploadFile(),
    },
    value: Boolean,
  },
  data() {
    return {
      errorCountLimit: defaultErrorCountLimit,
    }
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('close', value)
        this.errorCountLimit = defaultErrorCountLimit
      },
    },
  },
  methods: {
    showMoreErrors() {
      this.errorCountLimit += defaultErrorCountLimit
    },
  },
}
</script>
