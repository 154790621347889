import errorCodeMsg from './errorCodeMsg'

export default ({ isServerError = null, errorCode = null }, organisationUuid) => {
  if (errorCode) {
    // eslint-disable-next-line prettier/prettier
    return `${errorCodeMsg(errorCode)}. Please contact us quoting ref: ${errorCode}-${organisationUuid}`
  }

  if (isServerError) {
    return `A technical error occurred while trying to submit your files. Please contact us quoting ref: ${organisationUuid}`
  }

  return 'Please check your files for any errors and re-submit'
}
